const mobileNavigation = (() => {
    const mobileNavigation = document.getElementById('mobile-navigation');

    const toggle = () => {
        mobileNavigation.classList.toggle('d-none');
        mobileNavigation.classList.toggle('fade-in-out');
    }

    return {
        toggle,
    }
})();

const hero = (() => {
    const heroElement = document.querySelector('.navigation-hero');

    return {
        element: heroElement,
        available: !!heroElement,
    }
})();

const navbar = (() => {
    const customNavbar = document.querySelector('.custom-navbar--hero');
    const brand = customNavbar ? customNavbar.querySelector('.custom-navbar__nav__brand') : null;

    const handleIntersect = ([intersectionObserverEntry]) => {
        const black = getComputedStyle(document.documentElement).getPropertyValue('--bs-black');
        const white = getComputedStyle(document.documentElement).getPropertyValue('--bs-white');

        if (intersectionObserverEntry.isIntersecting) {
            customNavbar.classList.remove('bg-white');
            customNavbar.style.setProperty('--logo-color', white);
            if (brand) {
                brand.classList.add('svg-drop-shadow');
            }
        } else {
            customNavbar.classList.add('bg-white');
            customNavbar.style.setProperty('--logo-color', black);
            if (brand) {
                brand.classList.remove('svg-drop-shadow');
            }
        }
    }

    if (hero.available) {
        const observer = new IntersectionObserver(handleIntersect);
        observer.observe(hero.element);
    }

    return {
        element: customNavbar,
    };
})();

const handleToggle = (button) => {
    const body = document.body;

    button.addEventListener('click', () => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            mobileNavigation.toggle();
            body.classList.toggle('overflow-hidden');
        }, 0);
    });
}

// mobile toggle
(() => {
    const button = document.getElementById('mobile-navigation-toggle');
    if (button) {
        handleToggle(button);
    }
})();

// desktop toggle
(() => {
    const button = document.getElementById('desktop-navbar-toggle');

    if (button) {
        handleToggle(button);
    }
})();
