import {swipers as modalSwipers} from '../_layout/slider';
import Modal from 'bootstrap/js/src/modal';

const modalSliders = document.querySelectorAll('[id^=\'block-gallery-\']');

modalSliders.forEach((modalSlider) => {
    const gallery = modalSlider.querySelector('.block-gallery-images');
    const images = gallery.querySelectorAll('.block-gallery-image');
    const modalContainer = modalSlider.querySelector('.modal');
    const modal = new Modal(modalContainer);
    const innerSwiper = modalSwipers.find((modalSwiper) => modalContainer.contains(modalSwiper.el));

    images.forEach((image) => {
        image.addEventListener('click', () => {
            const imageId = image.dataset.imageId;
            const swiperImage = modalSlider.querySelector(`.swiper-slide[data-image-id='${imageId}']`);
            const swiperSlideIndex = swiperImage.dataset.swiperSlideIndex;
            let parsedIndex = parseInt(swiperSlideIndex);
            innerSwiper.slideTo(parsedIndex + 1);
            modal.show();
        });
    });
});
