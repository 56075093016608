const newsletterForm = (() => {
    const newsletterForm = document.getElementById('newsletter-form');
    const mailchimpFormLink = window.sessionStorage.getItem('mailchimpFormLink');

    if (newsletterForm) {
        newsletterForm.addEventListener('submit', (e) => {
            e.preventDefault();
            if (newsletterForm.checkValidity()) {
                const formData = new FormData(newsletterForm);
                const newsletterEmail = formData.get('newsletter-email');
                const navigateTo = (() => {
                    if (newsletterEmail) {
                        return `${mailchimpFormLink}&EMAIL=${encodeURIComponent(newsletterEmail)}`;
                    }

                    return mailchimpFormLink;
                })();
                window.open(navigateTo, '_blank');
            }
        });
    }

    return {}
})();
