import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/modal';

// Smooth Scrolling Polifill
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import '../_assets/scripts/tooltips';
import '../_assets/scripts/anchors';

// Layout
import './partials/navbar'
import './partials/footer'

// Blocks
import '../_blocks/index';
