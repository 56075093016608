import Tooltip from 'bootstrap/js/src/tooltip';

// property tooltips
(() => {
    const tooltipTriggers = document.querySelectorAll('[data-bs-toggle="property-tooltip"]');
    Array.from(tooltipTriggers).map((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl);
        tooltipTriggerEl.addEventListener('click', function () {
            tooltipTriggerEl.focus();
        });
    });
})();

// free entry tooltips
(() => {
    const tooltipTriggers = document.querySelectorAll('[data-bs-toggle="free-entry-tooltip"]');
    Array.from(tooltipTriggers).map((tooltipTriggerEl) => {
        new Tooltip(tooltipTriggerEl, {
            placement: 'top',
            trigger: 'focus'
        });
        tooltipTriggerEl.addEventListener('click', function () {
            tooltipTriggerEl.focus();
        });
    });
})();
